import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Layout from "../components/layout"
import logoImg from "../images/noah-logo-borderless.svg"

const Quote = ({ author, work, url, children, tr }) => {
  var citeDetails = work || ""
  if (tr) {
    citeDetails += ` (tr. ${tr})`
  }
  return (
    <blockquote style={{ margin: "1em 0 4em", border: "none", padding: 0 }}>
      <div style={{ fontFamily: '"Lora", serif' }}>{children}</div>
      <footer
        style={{ marginTop: ".5em", marginLeft: "2em", textIndent: "-1.25em" }}
      >
        <cite style={{ color: "#555", fontStyle: "normal" }}>
          &mdash;&nbsp;
          <span style={{ fontWeight: "bold" }}>{author}</span>
          {work && ", "}
          {url ? (
            <a href={url} style={{ color: "#55b", textDecoration: "none" }}>
              {citeDetails}
            </a>
          ) : (
            citeDetails
          )}
        </cite>
      </footer>
    </blockquote>
  )
}

export default () => (
  <Layout ccLicense={false}>
    <div style={{ margin: "15px" }}>
      <header style={{ display: "flex", margin: `60px 0 50px` }}>
        <Link to="/">
          <img style={{ width: "200px" }} alt="logo" src={logoImg} />
        </Link>
      </header>
      <h1 style={{ marginBottom: "4rem" }}>Quotes</h1>
      <Quote author="Hermann Hesse" work="The Glass Bead Game">
        A fancy-free artist avoids pure mathematics not because he understands
        it and could say something about it if he liked, but because he
        instinctively inclines towards other things. Such violent inclinations
        and disinclinations are signs by which you can recognize the pettier
        souls. In great souls, and superior minds, such passions are not found.
        Every of us is merely an experiment, a waystation. But each of us should
        be on the way towards perfection, should be striving to reach the
        center, not the periphery. Remember this: one can be a strict grammarian
        or logician, and at the same time be full of imagination and music.
      </Quote>

      <Quote author="Karl Popper" work="Conjectures and Refutations">
        <p>
          By the doctrine that truth is manifest I mean, you will recall, the
          optimistic view that truth, if put before us naked, is always
          recognizable as truth. Thus truth, if it does not reveal itself, has
          only to be unveiled, or dis-covered. Once this is done, there is no
          need for further argument. We have been given eyes to see the truth,
          and the 'natural light' of reason to see it by. [...]
        </p>
        <p>
          [But] the simple truth is that truth is often hard to come by, and
          that once found it may easily be lost again. Erroneous beliefs may
          have an astonishing power to survive, for thousands of years, in
          defiance of experience, with or without the aid of any conspiracy.
        </p>
      </Quote>

      <Quote author="Zhuangzi" tr="Brook Ziporyn">
        <p>
          Huizi said to Zhuangzi, “I have a huge tree which people call the
          Stink Tree. The trunk is swollen and gnarled, impossible to align with
          any level or ruler. The branches are twisted and bent, impossible to
          align to any T-square or carpenter’s arc. Even if it were growing
          right in the road, a carpenter would not give it so much as a second
          glance. And your words are similarly big but useless, which is why
          they are rejected by everyone who hears them.”
        </p>
        <p>
          Zhuangzi said, “[… You] have this big tree, and you worry that it’s
          useless. Why not plant it in our homeland of not-even-anything, the
          vast wilds of open nowhere? Then you could loaf and wander there,
          doing lots of nothing there at its side, and take yourself a nap,
          far-flung and unfettered, there beneath it. It will never be cut down
          by ax or saw. Nothing will harm it. Since it has nothing for which it
          can be used, what could entrap or afflict it?”
        </p>
      </Quote>

      <Quote
        author="Phil Fish"
        work="FEZ marketing website (2014)"
        url="https://web.archive.org/web/20140618172326/http://polytroncorporation.com/what-is-fez"
      >
        There are no enemies in FEZ. No bosses, no combat. In fact, no conflict
        of any kind. You can die, but there is no penalty for doing so. FEZ aims
        to create a non-threatening world rich with ambiance, a pleasant place
        to spend time in.
      </Quote>

      <Quote author="Morihei Ueshiba" work="Budō">
        The instructor can only impart a small portion of the teaching; only
        through ceaseless training can you obtain the necessary experience
        allowing you to bring these mysteries alive. Hence, do not chase after
        many techniques; one by one, make each technique your own.
      </Quote>

      <Quote
        author="Sir Arthur Eddington"
        work="The Nature of the Physical World"
      >
        There are some to whom the sense of a divine presence irradiating the
        soul is one of the most obvious things of experience.  In their view a
        man without this sense is to be regarded as we regard a man without a
        sense of humor.  The absence is a kind of mental deficiency.  We may try
        to analyze the experience as we analyze humor, and construct a theology,
        or it may be an atheistic philosophy, which shall put into scientific
        form what is to be inferred about it.  But let us not forget that the
        theory is symbolic knowledge whereas the experience is intimate
        knowledge.  And as laughter cannot be compelled by the scientific
        exposition of the structure of a joke, so a philosophic discussion of
        the attributes of God (or an impersonal substitute) is likely to miss
        the intimate response of the spirit which is the central point of the
        religious experience.
      </Quote>

      <Quote
        author="Mortimer J Adler and Charles van Doren"
        work="How To Read A Book"
      >
        Let us assume that the problem with which we began was not a simple one,
        but was rather one of those perennial problems with which thinkers have
        struggled for centures, and about which good men have disagreed and can
        continue to disagree. We should recognize, on this assumption, that our
        task as syntopical readers is not merely to answer the questions
        ourselves—the questions that we have so carefully framed and ordered
        both to elucidate the discussion of the subject and the subject itself.
        The truth about a problem of this sort is not found so easily. In fact,
        we would probably be presumptuous to expect that the truth could be
        found in any one set of answers to the questions. Rather, it is to be
        found, if at all, in the <i>conflict of opposing answers,</i> many if
        not all of which may have persuasive evidence and convincing reasons to
        support them.
      </Quote>

      <Quote
        author="Miguel de Cervantes Saavedra"
        work="Don Quixote"
        tr="JM Cohen"
      >
        You must consider what you are, seeking to know yourself, which is the
        most difficult task conceivable. From self-knowledge you will learn not
        to puff yourself up, like the frog who wanted to be as big as an ox.
      </Quote>

      <Quote author="Homer" work="The Odyssey" tr="Emily Wilson">
        <span style={{ display: "inline-block", width: "2em" }} />
        My crew <br />
        begged me to stop, and pleaded with me. <br />
        <br />
        <span style={{ display: "inline-block", width: "19.5em" }} />
        ‘Please! <br />
        Calm down! Why are you being so insistent <br />
        and taunting this wild man? He hurled that stone <br />
        and drove our ship right back to land. We thought <br />
        that we were going to die. If he had heard us, <br />
        he would have hurled a jagged rock and crushed <br />
        our heads and wooden ship. He throws so hard!’
        <br />
        <br />
        But my tough heart was not convinced; I was <br />
        still furious, and shouted back again. <br />
        <br />
        ‘Cyclops! If any mortal asks you how <br />
        your eye was mutilated and made blind, <br />
        say that Odysseus, the city-sacker, <br />
        Laertes' son, who lives in Ithaca, <br />
        destroyed your sight.’
      </Quote>

      <Quote author="Lu Xun" work="My Old Home">
        Hope cannot be said to exist, nor can it be said not to exist. It is
        just like roads across the earth. For actually the earth had no roads to
        begin with, but when many men pass one way, a road is made.
      </Quote>

      <Quote
        author="Diarmaid MacCulloch"
        work="Christianity: The First Three Thousand Years"
      >
        I was brought up in the presence of the Bible, and I remember with
        affection what it was like to hold a dogmatic position on the statements
        of Christianity. I would now describe myself as a candid friend of
        Christianity. I still appreciate the seriousness which a religious
        mentality brings to the mystery and misery of human existence, and I
        appreciate the solemnity of religious liturgy as a way of confronting
        these problems.
      </Quote>

      <Quote
        author="Edvard Grieg"
        work="personal letter recounting his time with Liszt"
        url="https://www.aspenmusicfestival.com/program_notes/view/grieg-piano-concerto-in-a-minor-op.-16"
      >
        In the very last bars, where the first note of the first
        triplet—G-sharp—in the orchestral part is changed to G-natural, while
        the piano runs through its entire compass in a powerful scale passage,
        [Liszt] suddenly jumped up, stretched himself to his full height, strode
        with theatrical gait and uplifted arm through the monastery hall, and
        literally bellowed out the theme. At that particular G-natural he
        stretched out his arm with an imperious gesture and exclaimed, “G, G,
        not G-sharp! Splendid! That’s the real thing!” And then, quite
        pianissimo and in parenthesis: “I had something of the kind the other
        day from Smetana.” He went back to the piano and played the whole thing
        over again. Finally he said in a strange, emotional way: “Keep on, I
        tell you. You have what is needed, and don’t let them frighten you.”
      </Quote>

      <Quote
        author="Sam Anderson"
        work="David's Ankles: How Imperfections Could Bring Down the World's Most Perfect Statue"
        url="https://www.nytimes.com/2016/08/21/magazine/davids-ankles-how-imperfections-could-bring-down-the-worlds-most-perfect-statue.html"
      >
        Perfection, it turns out, is no way to try to live. It is a child’s
        idea, a cartoon — this desire not to be merely good, not to do merely
        well, but to be faultless, to transcend everything, including the limits
        of yourself. It is less heroic than neurotic, and it doesn’t take much
        analysis to get to its ugly side: a lust for control, pseudofascist
        purity, self-destruction. Perfection makes you flinch at yourself,
        flinch at the world, flinch at any contact between the two. Soon what
        you want, above all, is escape: to be gone, elsewhere, annihilated.
      </Quote>

      <Quote
        author="Harold Abelson and Gerald Jay Sussman with Julie Sussman"
        work="Structure and Interpretation of Computer Programs"
        url="https://mitpress.mit.edu/sites/default/files/sicp/full-text/book/book-Z-H-7.html"
      >
        First, we want to establish the idea that a computer language is not
        just a way of getting a computer to perform operations but rather that
        it is a novel formal medium for expressing ideas about methodology.
        Thus, programs must be written for people to read, and only incidentally
        for machines to execute. Second, we believe that the essential material
        to be addressed by a subject at this level is not the syntax of
        particular programming-language constructs, nor clever algorithms for
        computing particular functions efficiently, nor even the mathematical
        analysis of algorithms and the foundations of computing, but rather the
        techniques used to control the intellectual complexity of large software
        systems.
      </Quote>

      <Quote
        author="Vincent van Gogh"
        work="personal letter to his brother Theo"
        url="http://www.vangoghletters.org/vg/letters/let400/letter.html"
      >
        If something in you yourself says ‘you aren’t a painter’ — IT’S THEN
        THAT YOU SHOULD PAINT, old chap, and that voice will be silenced too,
        but precisely because of that. Anyone who goes to his friends and
        complains about his troubles when he feels like that loses something of
        his manliness, something of the best that’s in him. Your friends can
        only be those who fight against it themselves, rouse the active in you
        through their own example of action.
      </Quote>

      <Quote author="John Stuart Mill" work="On Liberty">
        Protection, therefore, against the tyranny of the magistrate is not
        enough: there needs protection also against the tyranny of the
        prevailing opinion and feeling; against the tendency of society to
        impose, by other means than civil penalties, its own ideas and practices
        as rules of conduct on those who dissent from them; to fetter the
        development, and, if possible, prevent the formation, of any
        individuality not in harmony with its ways, and compel all characters to
        fashion themselves upon the model of its own. There is a limit to the
        legitimate interference of collective opinion with individual
        independence: and to find that limit, and maintain it against
        encroachment, is as indispensable to a good condition of human affairs,
        as protection against political despotism.
      </Quote>
    </div>
  </Layout>
)
